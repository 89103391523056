import createActions from '@/utils/createActions';
import createReducers from '@/utils/createReducers';

const initialState = {
    fwqInfo: [],
    logData: [],
    curData: [],
    logTime: {},
};

export const PRE_FIX = 'FWQ';

export const AJAX = {
    GET_FWQ_LIST: {
        url: 'monitor/fwqList',
        method: 'GET',
    }, // 获取服务器列表
    GET_COLLECT_DATA: {
        url: 'monitor/getLogData',
        method: 'GET',
    }, // 获取采集数据
    GET_CUR_DATA: {
        url: 'monitor/getCurData',
        method: 'GET',
    }, // 获取实时数据
};

// eslint-disable-next-line
const ACTIONS = createActions(AJAX);
// 这个怎么用还没想好

const fwqReducer = createReducers((state = initialState, action) => {
    const { type, payload, ip } = action;
    switch (type) {
        case 'GET_FWQ_LIST_SUCCESS':
            return {
                ...state,
                fwqInfo: payload,
            };
        case 'GET_CUR_DATA_SUCCESS':
            return {
                ...state,
                curData: {
                    ...state.curData,
                    [ip]: payload,
                },
            };
        case 'GET_COLLECT_DATA_SUCCESS':
            return {
                ...state,
                logData: {
                    ...state.logData,
                    [ip]: payload,
                },
            };
        default:
            return state;
    }
}, PRE_FIX);

export default fwqReducer;
