export const REQUEST_STATUS = {
    REQUEST: 'REQUEST',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
};

const createActions = (ajaxObj, PRE_FIX) => {
    const actions = {};

    Object.keys(ajaxObj).forEach(key => {
        const actionType = key;
        actions[`${actionType}_${REQUEST_STATUS.SUCCESS}`] = data => ({
            type: `${actionType}_${REQUEST_STATUS.SUCCESS}`,
            payload: data,
        });
        actions[`${actionType}_${REQUEST_STATUS.FAILURE}`] = error => ({
            type: `${actionType}_${REQUEST_STATUS.FAILURE}`,
            payload: error,
        });
        actions[`${actionType}_${REQUEST_STATUS.REQUEST}`] = loading => ({
            type: `${actionType}_${REQUEST_STATUS.REQUEST}`,
            payload: loading,
        });
    });

    return actions;
};

export default createActions;
