import { lazy } from 'react';

// 使用 React.lazy 动态导入组件
const FwqMonitor = lazy(() => import('../pages/monitor/fwq'));

const routes = [
    {
        path: '/fwqMonitor',
        Component: FwqMonitor,
    },
];

export default routes;
