import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import NotFound from '../components/NotFound';
import routes from './routes';
import { Spin } from 'antd';

const AppRouter = () => {
    return (
        <Router>
            <Suspense fallback={<Spin className="centered-spinner" size="large" />}>
                <Routes>
                    {routes.map(({ path, Component }) => (
                        <Route key={path} path={path} element={<Component />} />
                    ))}
                    <Route path="/" element={<Navigate to="/fwqMonitor" replace />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Suspense>
        </Router>
    );
};

export default AppRouter;
