import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

const store = configureStore({
    // 使用这个可以再action生成函数中使用异步
    reducer: rootReducer,
    devTools: process.env.NODE_ENV !== 'production',
});

window.store = store;

export default store;
