import { REQUEST_STATUS } from './createActions';

const createReducers = (reducer, PRE_FIX) => (state, action) => {
    const { type, payload, updateKey } = action;
    // 处理自定义更新逻辑
    if (type === `${PRE_FIX}_FILL` && updateKey) {
        return {
            ...state,
            [updateKey]: payload,
        };
    }
    if (type.endsWith(REQUEST_STATUS.REQUEST)) {
        return {
            ...state,
        };
    }
    if (type.endsWith(REQUEST_STATUS.FAILURE)) {
        return {
            ...state,
        };
    }
    //调用通用的reducer
    return reducer(state, action);
};

export default createReducers;
